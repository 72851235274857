<template>
    <div class="d-lg-none tw-block sm:tw-hidden">
        <div v-show="expandFilters" class="tw-bg-white filter-window">
            <div
                class="tw-flex tw-justify-center tw-mt-4"
                @click="changeExpandFilter"
            >
                <img
                    src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
                    class="arrow-svg"
                />
            </div>
            <div class="tw-flex tw-justify-center">
                <v-btn text class="tw-text-capitalize" color="black">
                    <img
                        src="@/assets/images/Icons/filter-icon.png"
                        class="filter-svg"
                    />
                    Filters
                </v-btn>
            </div>
            <div>
                <v-divider />
                <v-list-group
                    active-class="mobile-nav-active"
                    v-for="(filter, key) in filters"
                    :class="filter.key"
                    :key="key"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="filter.label"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <component :is="filter.component" :key="key"></component>
                    <v-divider />
                </v-list-group>
            </div>
            <div>
                <v-list>
                    <div class="tw-pb-4">
                        <v-list-item class="pt-2">
                            Preis
                        </v-list-item>
                        <v-list-item class="pt-2">
                            <v-text-field
                                class="price-input"
                                type="number"
                                tile
                                value="1"
                                append-icon="mdi-currency-eur"
                                outlined
                            ></v-text-field>
                            <div class="price-divider"></div>
                            <v-text-field
                                class="price-input"
                                type="number"
                                tile
                                value="200"
                                append-icon="mdi-currency-eur"
                                outlined
                            ></v-text-field>
                        </v-list-item>
                    </div>
                    <v-divider />
                    <div
                        class="tw-flex font-weight-meduim tw-justify-between tw-px-4 tw-items-center"
                    >
                        Nur Artikel im Sale anzeigen
                        <v-switch v-model="switch1"></v-switch>
                    </div>
                </v-list>
            </div>
            <!-- second filter list -->
            <div>
                <v-divider />
                <v-list-group
                    active-class="mobile-nav-active"
                    v-for="(filter, key) in filtersAdditional"
                    :class="filter.key"
                    :key="key"
                    no-action
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="filter.label"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <component :is="filter.component" :key="key"></component>
                    <!--need to add item content  -->
                    <v-divider />
                </v-list-group>
            </div>
            <!-- filter mobile buttons-->
            <div class="tw-p-2 tw-flex tw-justify-center tw-gap-3">
                <v-btn
                    depressed
                    class="mt-2 px-3 text-gray-400 price-b"
                    height="45"
                    tile
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    depressed
                    color="primary"
                    class="mt-2 px-3 flex-end"
                    height="45"
                    tile
                >
                    1.215 Produkte anzeigen
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
import MobileMarkeFilter from '@/pages/category/elements/MobileMarkeFilter';
import DefaultFilter from '@/components/content/items/filters/DefaultFilter';
import MobileSizeFilter from '@/pages/category/elements/MobileSizeFilter';
import DefaulMobiletFilter from '@/pages/category/elements/DefaulMobiletFilter';

export default {
    name: 'MobileFilter',
    props: {
        expandFilters: Boolean,
    },
    components: {
        MobileMarkeFilter,
        DefaultFilter,
        MobileSizeFilter,
        DefaulMobiletFilter,
    },
    methods: {
        changeExpandFilter() {
            this.expandFilters = !this.expandFilters;
            this.$emit('expandFilterEvent', this.expandFilters);
        },
    },
    data: () => ({
        switch1: false,
        filters: [
            { label: 'Marke', component: 'MobileMarkeFilter' },
            { label: 'Serie', component: 'DefaulMobiletFilter' },
            { label: 'Größe', component: 'MobileSizeFilter' },
            { label: 'Gepäckart', component: 'DefaulMobiletFilter' },
            { label: 'Rollensystem', component: 'DefaulMobiletFilter' },
        ],
        filtersAdditional: [
            { label: 'Farben', component: 'DefaulMobiletFilter' },
            { label: 'Volumen', component: 'DefaulMobiletFilter' },
            { label: 'Gewicht', component: 'DefaulMobiletFilter' },
            { label: 'Besonderheiten', component: 'DefaulMobiletFilter' },
            { label: 'Laptopfach', component: 'DefaulMobiletFilter' },
            { label: 'Schlossart', component: 'DefaulMobiletFilter' },
            { label: 'Material', component: 'DefaulMobiletFilter' },
        ],
    }),
};
</script>
<style scoped>
.v-list-item .v-btn {
    font-size: 10px !important;
}
.price-b.v-btn {
    color: gray;
}

.price-divider {
    width: 25px;
    border: 1px solid grey;
    background-color: grey;
    margin: 0px 10px;
    position: relative;
    bottom: 15px;
}
.v-list-group >>> .v-icon {
    transform: rotate(-90deg);
}
.article-number-budge {
    color: grey;
}
.v-input.v-select {
    max-width: 15%;
}
.filter-window {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
}
.filter-svg {
    width: 25px;
    margin-right: 5px;
    margin-bottom: 2px;
}
.v-btn:hover:before {
    opacity: 0;
    cursor: none;
}
.arrow-svg {
    transform: rotate(-90deg);
    width: 10px;
    margin-top: 5px;
    cursor: pointer;
}
</style>
