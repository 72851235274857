import { render, staticRenderFns } from "./SizeFilter.vue?vue&type=template&id=3db74c82&scoped=true&"
import script from "./SizeFilter.vue?vue&type=script&lang=js&"
export * from "./SizeFilter.vue?vue&type=script&lang=js&"
import style0 from "./SizeFilter.vue?vue&type=style&index=0&id=3db74c82&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3db74c82",
  null
  
)

export default component.exports