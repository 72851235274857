var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-list',[_c('div',{staticClass:"tw-pb-4"},[_c('v-list-item',{staticClass:"pt-2"},[_c('ItemGroup',{attrs:{"options":_vm.articleSizeOptions,"md":6},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var option = ref.option;
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"tw-flex tw-justify-center tw-items-center card-check tw-flex-col",style:({
                                        borderColor: active ? 'black' : '',
                                        color: active ? 'black' : '',
                                        borderWidth: active ? '2px' : '',
                                    }),attrs:{"height":"100","flat":""},on:{"click":toggle}},[_c('div',{staticClass:"font-weight-bold option-name"},[_vm._v(" "+_vm._s(option.name)+" ")]),_c('div',{staticClass:"tw-font-light option-description"},[_vm._v(" "+_vm._s(option.description)+" ")])])]}}])})],1)],1),_c('v-divider'),_c('v-list-item',{staticClass:"tw-py-2"},[_c('v-btn',{staticClass:"mt-2",attrs:{"depressed":"","block":"","color":"primary","height":"45","tile":""}},[_vm._v(" 30 Produkte anzeigen "),_c('img',{staticClass:"tw-w-4 tw-ml-2",attrs:{"src":require("@/assets/images/Icons/icon-pfeil-link_weiss.svg")}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }