<template>
    <div class="main-page-size tw-mt-0">
        <div
            v-show="expandFilters"
            class="tw-absolute  tw-z-0 tw-inset-0  tw-bg-black tw-bg-opacity-30"
        ></div>
        <MobileFilter
            class="tw-absolute  tw-z-10 mobile-filter-container"
            :expandFilters="expandFilters"
            @expandFilterEvent="expandFilters = $event"
        />
        <div
            class="tw-relative tw-w-full tw-mt-2 tw-mt-lg-8 sm:tw-px-5 xl:tw-px-0"
        >
            <div class="tw-grid sm:tw-grid-cols-12 tw-gap-2">
                <div
                    class="lg:tw-col-span-3  tw-block lg:tw-hidden"
                >
                    <div class="tw-flex tw-justify-between tw-pb-5">
                        <v-btn
                            class="tw-px-0 arrow-svg"
                            :class="
                                expandSideMenu
                                    ? 'reverse-rotate'
                                    : 'direct-rotate'
                            "
                            text
                            icon
                            @click="expandSideMenu = !expandSideMenu"
                        >
                            <img
                                src="@/assets/images/Icons/icon-pfeil-slider_links_schwarz.svg"
                            />
                        </v-btn>
                        <v-btn
                            text
                            class="tw-text-capitalize"
                            color="black"
                            @click="expandFilters = !expandFilters"
                        >
                            <img
                                src="@/assets/images/Icons/filter-icon.png"
                                class="filter-svg"
                            />
                            Filters
                        </v-btn>
                    </div>
                    <v-expand-transition>
                        <div v-show="expandSideMenu" class="pl-5 tw-pb-2">
                            <CategorySidebar />
                            <img
                                src="@/assets/images/category/sidebar_img.jpg"
                            />
                        </div>
                    </v-expand-transition>
                </div>
                <div
                    class="lg:tw-col-span-3 tw-hidden lg:tw-block tw-p-0 categories-container"
                >
                    <CategorySidebar />
                    <img src="@/assets/images/category/sidebar_img.jpg" /> 
                </div>
                <div
                    class="lg:tw-col-span-9 sm:tw-col-span-12 tw-col-span-12" 
                >
                    <BreadCrumbs :crumbs="this.breadCumbs" />
                    <div
                        class="tw-flex tw-justify-start tw-items-center tw-py-2 sm:tw-py-5"
                    >
                        <div class="tw-text-2xl sm:tw-text-3xl">Handgepäck</div>
                        <v-chip
                            class="ma-2 tw-font-medium article-number-budge"
                        >
                            1.215
                        </v-chip>
                    </div>
                    <Filters :showMoreFilters="expandFilters" />
                    <div
                        class="article-sort justify-end tw-items-center d-none d-lg-flex"
                    >
                        <div class="tw-pb-6 tw-flex tw-items-center">
                            <div>1 von 5</div>
                            <v-btn class="px-2" text icon color="grey">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </div>
                        <v-select
                            v-model="sort"
                            prepend-inner-icon="mdi-swap-vertical"
                            :items="sortAttributes"
                            outlined
                        ></v-select>
                    </div>
                    <ArticleGrid :articles="articles" />
                    <Seperator />
                    <CategoryKnowledge />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/content/layout/BreadCrumbs';
import Filters from '@/pages/category/elements/Filters';
import ArticleGrid from '@/components/content/items/article/ArticleGrid';
import CategoryKnowledge from '@/pages/category/elements/CategoryKnowledge';
import Seperator from '@/components/content/items/Seperator';
import CategorySidebar from '@/pages/category/elements/CategorySidebar';
import MobileFilter from '@/pages/category/elements/MobileFilter';

export default {
    name: 'Category',
    components: {
        CategorySidebar,
        ArticleGrid,
        Seperator,
        CategoryKnowledge,
        Filters,
        BreadCrumbs,
        MobileFilter,
    },

    data: () => ({
        expandSideMenu: false,
        expandFilters: false,
        sort: 'Sorting',
        sortAttributes: ['Sorting', 'Name', 'Price'],
        breadCumbs: [
            {
                label: 'Reisegepäck',
                link: '/kategorie',
            },
            {
                label: 'Handgepäck',
                link: '/kategorie',
            },
        ],
        articles: [
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '1',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['grey', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '2',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['red', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '4',
                price: '628,00',
                oldPrice: '628,00',
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '1',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'articlebanner',
                img: 'banner_article.jpg',
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '3',
                price: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '4',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'fullbanner',
                img: 'banner_full.jpg',
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '5',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '6',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '6',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '6',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '6',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'article',
                label: 'FPM Milano',
                discount: '-26%',
                shortDesc: 'BankZip Trunk on Wheels L Eclipse Black',
                img: '6',
                price: '628,00',
                oldPrice: '628,00',
                colors: ['black', 'blue'],
            },
            {
                type: 'halfbanner',
                img: 'banner_half.jpg',
            },
        ],
    }),
};
</script>
<style scoped>
.mobile-filter-container {
    position: fixed;
    height: 95%;
    overflow: auto;
    bottom: 0;
    right: 0;
}
.v-list-item .v-btn {
    font-size: 10px !important;
}
.price-b.v-btn {
    color: gray;
}

.price-divider {
    width: 25px;
    border: 1px solid grey;
    margin: 0px 10px;
    position: relative;
    bottom: 15px;
}
.v-list-group >>> .v-icon {
    transform: rotate(-90deg);
}
.article-number-budge {
    color: rgb(155, 155, 155);
    background: rgb(235, 235, 235) !important;
}
.v-input.v-select {
    max-width: 15%;
}
.filter-window {
    border-radius: 16px !important;
}
.v-select >>> .v-input__slot {
    border-radius: 0;
}

.v-select >>> .v-select__selections {
    color: grey;
}

.article-sort {
    color: grey;
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-90deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.filter-svg {
    width: 25px;
    margin-right: 5px;
    margin-bottom: 2px;
}
.arrow-svg img {
    width: 12px;
}
.arrow-svg {
    padding-right: 15px;
    color: white !important;
}
@media (max-width: 1335px) {
    .categories-container {
        padding-left: 4%;
    }
}
</style>
