<template>
  <ul class="crumb-menu lg:tw-mb-4 tw-hidden lg:tw-block" >
    <li v-for="(crumb, key) in this.crumbs" v-bind:key="key">
      <router-link :to="crumb.link" class="tw-font-light tw-text-sm" >
        {{crumb.label}} <span>></span>
      </router-link>
    </li>
  </ul>
</template>

<script>


export default {
  name: 'BreadCrumbs',
  props: {
    crumbs: Array
  }
}
</script>

<style scoped>

ul.crumb-menu  {
  padding-left: 0px;
}

.crumb-menu li:last-child a{
  color: #505660;
}

.crumb-menu li:last-child span{
  display: none;
}

.crumb-menu li{
  display: inline-block;
  margin-right: 8px;
  color: #505660;
}

.crumb-menu li a{
  color: #cccccc;
}

</style>