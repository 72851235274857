<template>
    <v-menu offset-y tile :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <button
                class="selectbox-filter tw-flex tw-justify-between tw-items-center tw-px-3"
                @click="toggleBrandFilter()"
                v-bind="attrs"
                v-on="on"
            >
                {{ data.label }}
                <v-icon
                    :class="showFilter ? 'reverse-rotate' : 'direct-rotate'"
                    color="#8a8a8a"
                    >mdi-chevron-down</v-icon
                >
            </button>
        </template>
        <v-card>
            <v-list>
                <div class="tw-pb-4">
                    <v-list-item class="pt-2">
                        <v-text-field
                            class="price-input"
                            type="number"
                            tile
                            value="1"
                            append-icon="mdi-currency-eur"
                            outlined
                        ></v-text-field>
                        <div class="price-divider"></div>
                        <v-text-field
                            class="price-input"
                            type="number"
                            tile
                            value="200"
                            append-icon="mdi-currency-eur"
                            outlined
                        ></v-text-field>
                    </v-list-item>
                </div>
                <v-divider />
                <div
                    class="tw-flex font-weight-bold tw-justify-between tw-px-4 tw-items-center"
                >
                    Nur Artikel im Sale anzeigen
                    <v-switch v-model="switch1"></v-switch>
                </div>
                <v-divider />
                <v-list-item class="tw-py-2">
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="mt-2"
                        height="45"
                        tile
                    >
                        15 Produkte anzeigen
                        <img
                            src="@/assets/images/Icons/icon-pfeil-link_weiss.svg"
                            class="tw-w-4 tw-ml-2"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: 'PriceFilter',
    props: {
        data: Object,
    },
    data() {
        return {
            switch1: true,
            showFilter: false,
        };
    },
    methods: {
        toggleBrandFilter() {
            this.showFilter = !this.showFilter;
        },
    },
};
</script>

<style scoped>
.selectbox-filter {
    position: relative;
    border: 1px solid #8a8a8a;
    color: #8a8a8a;
    height: 50px;
    text-align: left;
    text-indent: 6px;
}

.selectbox-filter img {
    position: absolute;
    display: inline-block;
    color: #8a8a8a;
    height: 23px;
    width: 10px;
    right: 16px;
    top: 12px;
}

.svg-grey {
    fill: #8a8a8a;
}

.v-input >>> fieldset {
    border-color: #9e9e9e;
    color: #858585;
}
.v-input >>> label {
    color: #858585;
    font-weight: 300;
    font-size: 20px;
}

.v-input.price-input >>> .v-input__slot {
    border-radius: 0;
    width: 150px;
}

.price-divider {
    width: 25px;
    border: 1px solid grey;
    margin: 0px 10px;
    position: relative;
    bottom: 15px;
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.v-icon {
    font-size: 30px;
}
</style>
