<template>
<v-card>
            <v-list>
                <div class="tw-pb-4">
                    <v-list-item
                        class="pt-2 tw-flex tw-items-center tw-justify-center"
                    >
                        <div class="font-weight-bold text-lg">
                            No Filter!
                        </div>
                    </v-list-item>
                </div>
                <v-divider />
                <v-list-item class="tw-py-2">
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="mt-2"
                        height="45"
                        tile
                    >
                        0 Produkte anzeigen
                        <img
                            src="@/assets/images/Icons/icon-pfeil-link_weiss.svg"
                            class="tw-w-4 tw-ml-2"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card>
</template>

<script>
export default {
    name: 'DefaultFilter',
    props: {
        data: Object,
    },
    data() {
        return {
            showFilter: false,
            brand: 'aevor',
        };
    },
    methods: {
        toggleBrandFilter() {
            this.showFilter = !this.showFilter;
        },
    },
};
</script>

<style scoped>
.selectbox-filter {
    position: relative;
    border: 1px solid #8a8a8a;
    color: #8a8a8a;
    height: 50px;
    text-align: left;
    text-indent: 6px;
}

.selectbox-filter img {
    position: absolute;
    display: inline-block;
    color: #8a8a8a;
    height: 23px;
    width: 10px;
    right: 16px;
    top: 12px;
}

.svg-grey {
    fill: #8a8a8a;
}

@media (min-width: 959px) {
    .v-menu__content {
        width: 30%;
    }
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.v-icon {
    font-size: 30px;
}
</style>
