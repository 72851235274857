<template>
<v-card>
            <v-list>
                <div class="tw-pb-4">
                    <v-list-item class="pt-2">
                        <ItemGroup :options="articleSizeOptions" :md="6">
                            <template slot-scope="{ option, active, toggle }">
                                <v-card
                                    :style="{
                                        borderColor: active ? 'black' : '',
                                        color: active ? 'black' : '',
                                        borderWidth: active ? '2px' : '',
                                    }"
                                    class="tw-flex tw-justify-center tw-items-center card-check tw-flex-col"
                                    height="100"
                                    flat
                                    @click="toggle"
                                >
                                    <div class="font-weight-bold option-name">
                                        {{ option.name }}
                                    </div>

                                    <div
                                        class="tw-font-light option-description"
                                    >
                                        {{ option.description }}
                                    </div>
                                </v-card>
                            </template>
                        </ItemGroup>
                    </v-list-item>
                </div>
                <v-divider />
                <v-list-item class="tw-py-2">
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="mt-2"
                        height="45"
                        tile
                    >
                        30 Produkte anzeigen
                        <img
                            src="@/assets/images/Icons/icon-pfeil-link_weiss.svg"
                            class="tw-w-4 tw-ml-2"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card>
</template>

<script>
import ItemGroup from '@/components/shared/item-group/ItemGroup';
import { articleSizeOptions } from '@/services/data-mock/articleSizeOptions';
export default {
    name: 'SizeMobileFilter',
    components: {
        ItemGroup,
    },
    data() {
        return {
            articleSizeOptions,
            showFilter: false,
        };
    },
    props: {
        data: Object,
    },
    methods: {
        toggleBrandFilter() {
            this.showFilter = !this.showFilter;
        },
    },
};
</script>

<style scoped>
.selectbox-filter {
    position: relative;
    border: 1px solid #8a8a8a;
    color: #8a8a8a;
    height: 50px;
    text-align: left;
    text-indent: 6px;
}

.selectbox-filter img {
    position: absolute;
    display: inline-block;
    color: #8a8a8a;
    height: 23px;
    width: 10px;
    right: 16px;
    top: 12px;
}

.svg-grey {
    fill: #8a8a8a;
}
@media (min-width: 959px) {
    .v-menu__content {
        width: 30%;
    }
}
.card-check {
    border: 1px solid #9e9e9e;
    border-radius: 0;
    color: #9e9e9e;
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.v-icon {
    font-size: 30px;
}
</style>
