<template>
  <div class="tw-grid lg:tw-grid-cols-4 tw-mb-8">
    <div v-for="(article, key) in this.articles" :key="key" :class="colSize[article.type]">
      <ArticleElement v-if="article.type === 'article'" :article="article" />
      <div v-if="article.type === 'fullbanner'" class="tw-col-span-8 tw-mt-4 tw-mb-8" >
        <img :src="require(`@/assets/images/category/${article.img}`)" alt="Fullbanner"/>
      </div>
      <div v-if="article.type === 'halfbanner'" class="tw-col-span-2" style="margin-left: 16px; padding-bottom: 16px;" >
        <img :src="require(`@/assets/images/category/${article.img}`)" alt="Halfbanner"  />
      </div>
      <div v-if="article.type === 'articlebanner'" class="tw-col-span-1"  style="margin-left: 16px; margin-right: 16px;" >
        <img :src="require(`@/assets/images/category/${article.img}`)" alt="Article Banner"  />
      </div>
    </div>
  </div>
</template>

<script>
/* types are:
 *
 */

import ArticleElement from "@/components/content/items/article/ArticleElement";
export default {
  name: 'Articletw-grid',
  components: {ArticleElement},
  props: {
    articles: Array
  },
  data: () => (
      {
        colSize: {
            article: 'tw-col-span-2 md:tw-col-span-2 lg:tw-col-span-1',
            fullbanner: 'tw-col-span-4',
            halfbanner: 'tw-col-span-4 lg:tw-col-span-2',
            articlebanner: 'tw-col-span-2 lg:tw-col-span-1',
        },
    }),
};
</script>

<style scoped>


</style>
