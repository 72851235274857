<template>
    <v-menu offset-y tile :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
            <button
                @click="toggleBrandFilter()"
                class="selectbox-filter tw-flex tw-justify-between tw-items-center tw-px-3"
                v-bind="attrs"
                v-on="on"
            >
                {{ data.label }}
                <v-icon
                    :class="showFilter ? 'reverse-rotate' : 'direct-rotate'"
                    color="#8a8a8a"
                    >mdi-chevron-down</v-icon
                >
            </button>
        </template>
        <v-card>
            <v-list>
                <div class="tw-pb-4">
                    <v-list-item class="pt-2">
                        <v-text-field
                            rounded
                            dense
                            filled
                            class="tw-font-bold filter-input-label"
                            label="Nach Marke suchen"
                        >
                            <template v-slot:append>
                                <SvgSearch
                                    :height="'22'"
                                    :width="'22'"
                                    :color="'#8a8a8a'"
                                />
                            </template>
                        </v-text-field>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="Aevor (2)"
                            color="black"
                            class="checkbox-active"
                            value="aevor"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="Affenzahen(14)"
                            color="black"
                            class="checkbox-active"
                            value="affenzahen"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="Aleon(3)"
                            color="black"
                            class="checkbox-active"
                            value="aleon"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="Umaxx(9)"
                            color="black"
                            class="checkbox-active"
                            value="umaxx"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="American Tourister(342)"
                            color="black"
                            class="checkbox-active"
                            value="americanTourister"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                    <v-list-item>
                        <v-checkbox
                            v-model="brand"
                            label="Bench(342)"
                            color="black"
                            class="checkbox-active"
                            value="bench"
                            hide-details
                        ></v-checkbox>
                    </v-list-item>
                </div>
                <v-divider />
                <v-list-item class="tw-py-2">
                    <v-btn
                        depressed
                        block
                        color="primary"
                        class="mt-2"
                        height="45"
                        tile
                    >
                        35 Produkte anzeigen
                        <img
                            src="@/assets/images/Icons/icon-pfeil-link_weiss.svg"
                            class="tw-w-4 tw-ml-2"
                        />
                    </v-btn>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import SvgSearch from '../../../shared/icons/SvgSearch';
export default {
    name: 'BrandFilter',
    components: {
        SvgSearch,
    },
    data() {
        return {
            showFilter: false,
            brand: 'aevor',
        };
    },
    props: {
        data: Object,
    },
    methods: {
        toggleBrandFilter() {
            this.showFilter = !this.showFilter;
        },
    },
};
</script>

<style scoped>
.selectbox-filter {
    position: relative;
    border: 1px solid #8a8a8a;
    color: #8a8a8a;
    height: 50px;
    text-align: left;
    text-indent: 6px;
}

.selectbox-filter img {
    position: absolute;
    display: inline-block;
    color: #8a8a8a;
    height: 23px;
    width: 10px;
    right: 16px;
    top: 12px;
}

.svg-grey {
    fill: #8a8a8a;
}

.v-input >>> .v-input__slot {
    border-radius: 0;
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.v-icon {
    font-size: 30px;
}
.v-input--selection-controls.v-input.v-input--is-label-active >>> label {
    font-weight: 600;
    color: black;
}

.v-input--selection-controls {
    margin-top: 5px;
    padding-top: 0;
}

.filter-input-label .v-text-field__slot label {
    font-size: 12px;
}
</style>
