<template>
    <div class="tw-pb-4">
        <div
            class="tw-hidden sm:tw-grid lg:tw-grid-cols-6 md:tw-grid-cols-4 sm:tw-grid-cols-3  tw-gap-1"
        >
            <template v-for="(filter, key) in filters">
                <component
                    :is="filter.component"
                    :data="filter"
                    :key="key"
                ></component>
            </template>
        </div>
        <div class="tw-grid  tw-grid-cols-2 tw-gap-1 sm:tw-hidden">
            <template v-for="(filter, key) in filtersLabelMobile">
                <div
                    class="tw-font-medium tw-py-2 tw-items-center tw-px-3 tw-border-2 tw-text-gray-disabled"
                    :key="key"
                >
                    {{ filter.label }}
                </div>
            </template>
        </div>
        <div class="tw-hidden sm:tw-flex ">
            <v-expand-transition>
                <div
                    v-show="showMore || showMoreFilters"
                    class="tw-grid tw-mt-2 lg:tw-grid-cols-6 md:tw-grid-cols-4 sm:tw-grid-cols-3 tw-grid-cols-2 tw-gap-1"
                >
                    <template v-for="(filter, key) in filtersAdditional">
                        <component
                            :is="filter.component"
                            :data="filter"
                            :key="key"
                        ></component>
                    </template>
                </div>
            </v-expand-transition>
        </div>

        <div class="tw-mb-8 tw-mt-8 d-none d-lg-block d-xl-none">
            <PlainButton
                label="Mehr Filter"
                v-on:click.native="toggleShowMore()"
                :isOpen="showMore"
            />
        </div>
        <div class="d-none d-lg-block d-xl-none">
            <v-divider />
            <v-chip
                v-if="chip1"
                class="ma-2"
                close
                @click:close="chip1 = false"
            >
                Aevor
            </v-chip>
            <v-chip
                v-if="chip2"
                class="ma-2"
                close
                @click:close="chip2 = false"
            >
                Groß
            </v-chip>
            <v-chip
                v-if="chip3"
                class="ma-2"
                close
                @click:close="chip3 = false"
            >
                1 € - 200 €
            </v-chip>

            <v-btn
                text
                rounded
                :loading="loading3"
                :disabled="loading3"
                class="tw-font-light"
                @click="loader = 'loading3'"
            >
                <img
                    src="@/assets/images/Icons/icon-rueckgaberecht-schwarz.svg"
                    class="tw-w-4 tw-mr-2"
                />
                Alle zurücksetzen
            </v-btn>
            <v-divider />
        </div>
    </div>
</template>

<script>
import BrandFilter from '@/components/content/items/filters/BrandFilter';
import PriceFilter from '@/components/content/items/filters/PriceFilter';
import SizeFilter from '@/components/content/items/filters/SizeFilter';
import DefaultFilter from '@/components/content/items/filters/DefaultFilter';
import PlainButton from '@/components/shared/buttons/PlainButton';

export default {
    name: 'Filters',
    components: {
        PlainButton,
        BrandFilter,
        PriceFilter,
        SizeFilter,
        DefaultFilter,
    },
    props: {
        showMoreFilters: Boolean,
    },
    data: () => ({
        loader: null,
        loading3: false,
        chip1: true,
        chip2: true,
        chip3: true,
        filters: [
            { label: 'Marke', component: 'BrandFilter' },
            { label: 'Serie', component: 'DefaultFilter' },
            { label: 'Größe', component: 'SizeFilter' },
            { label: 'Gepäckart', component: 'DefaultFilter' },
            { label: 'Rollensystem', component: 'DefaultFilter' },
            { label: 'Preis', component: 'PriceFilter' },
        ],
        filtersLabelMobile: [
            { label: 'Handgepäck Trolleys' },
            { label: 'Handgepäck Taschen' },
        ],
        filtersAdditional: [
            { label: 'Farben', component: 'DefaultFilter' },
            { label: 'Volumen', component: 'DefaultFilter' },
            { label: 'Gewicht', component: 'DefaultFilter' },
            { label: 'Besonderheiten', component: 'DefaultFilter' },
            { label: 'Laptopfach', component: 'DefaultFilter' },
            { label: 'Schlossart', component: 'DefaultFilter' },
            { label: 'Material', component: 'DefaultFilter' },
        ],
        showMore: false,
    }),
    watch: {
        loader() {
            const l = this.loader;
            this[l] = !this[l];

            setTimeout(() => (this[l] = false), 2000);

            this.loader = null;
        },
    },
    methods: {
        toggleShowMore() {
            this.showMore = !this.showMore;
        },
    },
};
</script>
<style scoped>
.v-list {
    overflow-y: auto;
}

.v-list-group >>> .v-icon {
    transform: rotate(-90deg);
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
}
@-moz-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@-o-keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
@keyframes loader {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
.v-btn {
    text-transform: none;
}
</style>
