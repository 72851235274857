<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 25.5 25.5"
        :aria-labelledby="iconName"
        role="presentation"
        :style="{ fill: color }"
    >
        <title :id="iconName" lang="en">{{ iconName }} icon</title>
        <path
            id="Pfad_188"
            data-name="Pfad 188"
            d="M24.2,23.073l-4.279-4.278A11.351,11.351,0,1,0,11.354,22.7a11.868,11.868,0,0,0,3.484-.546,10.956,10.956,0,0,0,3.961-2.24L23.079,24.2a.8.8,0,0,0,.561.236A.778.778,0,0,0,24.2,24.2a.8.8,0,0,0,0-1.122ZM1.588,11.351a9.76,9.76,0,1,1,9.76,9.764,9.769,9.769,0,0,1-9.76-9.764Zm0,0"
            transform="translate(0.5 0.5)"
            :fill="color"
            :stroke="color"
            stroke-width="0.5"
        />
    </svg>
</template>
<script>
export default {
    name: 'SvgSearch',
    props: {
        iconName: {
            type: String,
            default: 'purple',
        },
        width: {
            type: [Number, String],
            default: 20,
        },
        height: {
            type: [Number, String],
            default: 20,
        },
        color: {
            type: String,
            default: '#26348c',
        },
    },
};
</script>
