const articleSizeOptions = [
    {
        name: 'Handgepäck',
        description: '2 Tage verreisen',
    },
    {
        name: 'Groß',
        description: 'Zweiwöchig Reise',
    },
    {
        name: 'Mittelgroß',
        description: 'Einwöchig Reise',
    },
    {
        name: 'Extra Groß',
        description: 'Zweiwöchig Reise',
    },
];

export { articleSizeOptions };
