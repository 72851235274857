<template>
    <div class="tw-font-light">
        <h2 class="tw-font-normal tw-text-xl-base tw-pb-2">
            Reisegepäck
            <v-btn
                class="px-2"
                :class="expandMainMenu ? 'reverse-rotate' : 'direct-rotate'"
                text
                icon
                @click="expandMainMenu = !expandMainMenu"
            >
                <v-icon color="black" large>mdi-chevron-down</v-icon>
            </v-btn>
        </h2>
        <v-expand-transition>
            <ul v-show="expandMainMenu" class="tw-pl-4 tw-pb-4">
                <li class="tw-pb-1 tw-text-lg">
                    Trolleys
                </li>
                <li>
                    <h3 class="tw-font-normal tw-text-lg tw-pb-2">
                        Handgepäck
                        <v-btn
                            class="px-2"
                            :class="
                                expandNestedMenu
                                    ? 'reverse-rotate'
                                    : 'direct-rotate'
                            "
                            text
                            icon
                            @click="expandNestedMenu = !expandNestedMenu"
                        >
                            <v-icon color="black" large
                                >mdi-chevron-down</v-icon
                            >
                        </v-btn>
                    </h3>
                    <v-expand-transition>
                        <ul v-show="expandNestedMenu" class="tw-pl-4 tw-pb-4">
                            <li
                                v-for="(menuPoint, key) in subMenu"
                                :key="key"
                                class="tw-pb-1 tw-text-sm"
                            >
                                {{ menuPoint.label }}
                            </li>
                        </ul>
                    </v-expand-transition>
                </li>
                <li v-for="(menuPoint, key) in menu" :key="key" class="tw-pb-1 tw-text-lg">
                    {{ menuPoint.label }}
                </li>
            </ul>
        </v-expand-transition>
    </div>
</template>

<script>
export default {
    name: 'CategorySidebar',
    data: () => ({
        expandMainMenu: true,
        expandNestedMenu: true,
        menu: [
            { label: 'Große Koffer' },
            { label: 'Koffer Sets' },
            { label: 'Reisetaschen' },
            { label: 'Kleidersäcke' },
            { label: 'Beatycases' },
            { label: 'Sondergepäck' },
            { label: 'Auto Reisetaschen Sets' },
        ],
        subMenu: [
            { label: 'Handgepäck Trolleys' },
            { label: 'Handgepäck Taschen' },
            { label: 'Handgepäck Rucksäcke' },
            { label: 'Handgepäck Trolleys' },
            { label: 'Flugtaschen' },
        ],
    }),
};
</script>
<style scoped>
.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}
</style>
