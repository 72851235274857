import { render, staticRenderFns } from "./ArticleGrid.vue?vue&type=template&id=c3cd7948&scoped=true&"
import script from "./ArticleGrid.vue?vue&type=script&lang=js&"
export * from "./ArticleGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3cd7948",
  null
  
)

export default component.exports