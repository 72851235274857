<template>
    <button class="plain-button tw-font-light">
        {{ label }}
        <v-icon
            :class="isOpen ? 'reverse-rotate' : 'direct-rotate'"
            color="#8a8a8a"
            >mdi-chevron-down</v-icon
        >
    </button>
</template>

<script>
export default {
    name: 'PlainButton',
    props: {
        label: String,
        isOpen: Boolean,
    },
};
</script>

<style scoped>
.plain-button {
    color: #8a8a8a;
    text-align: left;
}

.plain-button img {
    display: inline-block;
    color: #8a8a8a;
    height: 22px;
    margin-left: 16px;
}

.reverse-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(-180deg);
}

.direct-rotate {
    transition: all 0.1s 0.1s;
    transform: rotate(0deg);
}

.v-icon {
    font-size: 30px;
}
</style>
