<template>
    <div class="container tw-font-light">
        <h2 class="tw-text-2xl tw-mb-8 tw-text-center tw-font-normal">
            Wissenswertes zum Thema Handgepäck
        </h2>
        <div class="tw-grid tw-gap-6 tw-grid-cols-1 lg:tw-grid-cols-2">
            <div><img src="@/assets/images/category/bsp_cat_1.jpg" /></div>
            <div>
                <p class="tw-mb-4">
                    Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle
                    Buchstaben da sind und wie sie aussehen. Manchmal benutzt
                    man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um
                    Schriften zu testen. Manchmal Sätze, die alle Buchstaben des
                    Alphabets enthalten - man nennt diese Sätze »Pangrams«. Sehr
                    bekannt ist dieser: The quick brown fox jumps over the lazy
                    old dog.
                </p>
                <h3 class="tw-text-2xl tw-mb-4 tw-font-normal">
                    Welche Arten Handgepäck gibt es
                </h3>
                <p>
                    In Lateinisch sieht zum Beispiel fast jede Schrift gut aus.
                    Quod erat demonstrandum. Seit 1975 fehlen in den meisten
                    Testtexten die Zahlen, weswegen nach TypoGb. 204 § ab dem
                    Jahr 2034 Zahlen in 86 der Texte zur Pflicht werden.
                    Nichteinhaltung wird mit bis zu 245 € oder 368 $ bestraft.
                    Genauso wichtig in sind mittlerweile auch Âçcèñtë, die in
                    neueren Schriften aber fast immer enthalten sind.
                </p>
            </div>

            <div>
                <h3 class="tw-text-2xl tw-mb-4 tw-font-normal">
                    Wie sieht das ideal Handgepäck aus
                </h3>
                <p>
                    Dies ist ein Typoblindtext. An ihm kann man sehen, ob alle
                    Buchstaben da sind und wie sie aussehen. Manchmal benutzt
                    man Worte wie Hamburgefonts, Rafgenduks oder Handgloves, um
                    Schriften zu testen.
                    <br />Manchmal Sätze, die alle Buchstaben des Alphabets
                    enthalten - man nennt diese Sätze »Pangrams«. Sehr bekannt
                    ist dieser: The quick brown fox jumps over the lazy old dog.
                    Oft werden in Typoblindtexte auch fremdsprachige Satzteile
                    eingebaut (AVAIL® and Wefox™ are testing aussi la Kerning),
                    um die Wirkung in anderen Sprachen zu testen.
                    <br />
                    In Lateinisch sieht zum Beispiel fast jede Schrift gut aus.
                    Quod erat demonstrandum. Seit 1975 fehlen in den meisten
                    Testtexten die Zahlen, weswegen nach TypoGb. 204 § ab dem
                    Jahr 2034 Zahlen in 86 der Texte zur Pflicht werden.
                </p>
            </div>
            <div>
                <img src="@/assets/images/category/bsp_cat_2.jpg" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryKnowledge',
};
</script>
